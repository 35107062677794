import React, { useState, useEffect } from 'react';
import '@aws-amplify/ui-react/styles.css';

import { StairazAuth } from '../components/userAuth.js';
import { serviceResumesByUserprofileID,getUserProfile } from '../graphql/queries.js';

import { generateClient } from 'aws-amplify/api';
import { FaCoins } from 'react-icons/fa';
import { navigate } from 'gatsby';
import { deleteServiceResume } from '../graphql/mutations.js';
import NavBar from '../components/navBar.js';
import "../styles/userProfile.css"



function App({ signOut, user }) {
  // State to hold the active service resume
  const [activeServiceResume, setActiveServiceResume] = useState(null);
  const [loading, setLoading] = useState(true);
  const [userCredit, setUserCredit] = useState(null);

  const client = generateClient();

  // Inline CSS styles
  

  const handleNavigateToResume = () => {
    navigate('/career/service/resume-builder-ai'); // Navigate to the main resume page
  };

  const getExistingServiceResume = async (userProfileID) => {
    try {
      const param = {
        'userprofileID': userProfileID,
        'filter': {
          active: {
            eq: true // This filters for items where active is equal to true
          }
        }
      }
      const serviceResumeData = await client.graphql({query:serviceResumesByUserprofileID, variables:param});
      // Assuming that the query returns the first item in an array from the results
      return serviceResumeData.data.serviceResumesByUserprofileID.items[0]; // Take the first item
    } catch (error) {
      console.error('Error fetching ServiceResume:', error);
      return null; // or you could re-throw the error depending on your error handling strategy
    }
  };

  const getUserProfileData = async (userProfileID) => {
    try {
      const userProfileData = await client.graphql({query: getUserProfile,variables: { id: userProfileID }});
      setUserCredit(userProfileData.data.getUserProfile.credit); // Assuming the credit field is directly on the getUserProfile query
    } catch (error) {
      console.error('Error fetching UserProfile:', error);
    }
  };

  const handleDeleteResume = async () => {
    // This function would call a mutation to delete the active service resume
    // You would need to have the GraphQL mutation set up and imported to use here.
    try {
      const deleteResponse = await client.graphql({query: deleteServiceResume, variables:{ input: { id: activeServiceResume.id } }});
      console.log('Resume deleted:', deleteResponse);
      setActiveServiceResume(null); // Update local state after deletion
    } catch (error) {
      console.error('Error deleting resume:', error);
    }
  };

  

  useEffect(() => {
    if (user) {
      const userProfileID = user.username;
      
      getExistingServiceResume(userProfileID).then(data => {
      setActiveServiceResume(data);
      getUserProfileData(userProfileID);
   
      setLoading(false);
      });
      
    }
  }, [user]);

  return (
    
    <div className='appContainer'>
      <div style={{ width: '100%' }}>
        <NavBar />
      </div>
      <div className='pageFrame'>
        <h1>Welcome </h1>
        
        <hr className='sectionDivider'/>
        {loading ? (
          <p>Loading profile...</p>
        ) : (
          <>
            {/* <div>
              <FaCoins style={{ color: 'goldenrod', verticalAlign: 'middle' }} />
              <span style={{ marginLeft: '10px' }}>Credits: {userCredit}</span>
            </div> */}
    
           <p className="dividerText">AI Resume Builder</p>
          <hr className='sectionDivider'/>
       
         
            <>
              {activeServiceResume ? (
            <>
              <p>First Name: {activeServiceResume.basicInfo.firstName}</p>
              <p>Last Name: {activeServiceResume.basicInfo.lastName}</p>
              <p>Job Title: {activeServiceResume.jobTitle}</p>
              <p>Last Updated: {new Date(activeServiceResume.updatedAt).toLocaleString()}</p>
              <button className='button'  onClick={handleNavigateToResume}>Edit Resume</button>
              <button className='button'  onClick={handleDeleteResume}>Delete Resume</button>
            </>
          ) : (
            <>
              <p>No active resume found.</p>
              <button className='button'  onClick={handleNavigateToResume}>Build a new resume</button> {/* Button to start a new service */}
            </>
          )}
            </>
          
          </>
        )}
        <hr className='sectionDivider' />
        <button className='button' onClick={signOut}>Sign out</button>
      </div>
    </div>
   
  );
}

// Export the App wrapped with the withUserAuth HOC
export default StairazAuth(App);
